import styled from  'styled-components';
import {ModalTitle} from "react-bootstrap";
import { Modal } from 'react-bootstrap';
const BoldFont = 'Rubik-Medium';
const StyledTitle = styled(ModalTitle)`
  font-size: 18px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  font-family: ${BoldFont};
  text-align: center;
`;
const StyledSubTitle = styled.p`
  font-size: 14px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  text-align: center;

`;
const FormWrapper = styled.form`
  width: 100%;
  background-color: white;
  text-align: center;
  padding:20px 25px 25px 25px;
`;
const InputFieldContainer = styled.div`
  width: 100%;
  text-align: center;
  border: 1px solid #669db7;
  border-radius: 6px;
  background-color: ${({bgColor}) => bgColor ? bgColor : 'rgba(156, 156, 156, 0.1)' } ;
  display: flex;
   > input {
      width: 90%;
      border-radius: 4px;
      background-color: ${({bgColor}) => bgColor ? bgColor : 'rgba(156, 156, 156, 0.1)' };
      padding: 0 10px;
      padding-left: 10px;
      border: none;
      padding-right: 0px;
      color: ${({color}) => color ? color : 'rgb(156, 156, 156)' };
      font-family: rubik-medium;
      font-size: 16px;
      font-family: Rubik;
font-style: normal;
font-weight: normal;
font-size: 18px;
line-height: 21px;
text-align: center;
/* color: #9C9C9C; */
::placeholder {
  color:${({pColor}) => pColor ? pColor : 'rgb(156,156,156)' } ;
}
text-align: initial;
      &:focus, &:active, &:hover {
        outline: none;
      }
    }
    >button{
      width: 35%;
    }
    .image{
      width: 10%;
      display: flex;
      justify-content: flex-end;
      background-color: rgba(156, 156, 156, 0.1);
    >img{
      height: 37px;
      width: auto;
      margin: auto;
      margin: 5px;
    }
  }
   @media(max-width: 600px){
     display: flex;
     align-items: center;
     justify-content: center;
     >button{
       width: 40%;
     }
     > input {
        height: 40px;
        margin-top: 0px;
     }
   }
`;
const SearchButton = styled.button`
    text-transform: uppercase;
    padding: 0 20px;
    height: 35px;
    border-radius: 17px;
    background-color: #159fc9;
    border: none;
    color: white;
    font-family: ${BoldFont};
    font-size: 12px;
    -webkit-letter-spacing: 0px;
    -moz-letter-spacing: 0px;
    -ms-letter-spacing: 0px;
    letter-spacing: 0px;
    color: rgb(255,255,255);
    outline: none;
`;
const AddButton = styled.button`
    width: 110px;
    border-radius: 3px;
    background-color: #005c87;
    border: none;
    position: relative;
    font-size: 16px;
    color: White;
    font-family: rubik-medium;
    text-align: center;
    padding: 10px 0;
    outline: none;
    line-height: 20px;
 `;
const AttendeesContainer = styled.div`
    max-height: calc(100vh - 272px);
    overflow-x: hidden;
    overflow-y: auto;
    overflow: -moz-scrollbars-none;
    margin: 0px 25px 0px 25px;
    /* border: 1px solid #6399af; */
    border-top: none;
    border-right:none;
    margin-bottom: ${({margin}) => margin ? margin : '0px' };
    ::-webkit-scrollbar {
     width: 5px;
     height: 5px;
   }

    ::-webkit-scrollbar-thumb {
     background: #b3cfdb;
     cursor: pointer;
     border-radius: 2.5px;
   }

    ::-webkit-scrollbar-track {
     background: #fff;
     border-radius: 2.5px;
   }    
`;

const StyledModal = styled(Modal)`
  .modal-dialog {
    width: 40%;
    .modal-content{
      border-radius: 7px 7px 6px 6px;
    }
    .modal-header {
      background: linear-gradient(180deg,#52A8ED 0%,#8AD3F7 100%);
      border-radius: 6px 6px 0px 0px;
    }
    .modal-body{
      padding: 5px 0 0 0;
    }
    @media (max-width: 1000px) {
      width: 60%;
    }
    
    @media (max-width: 767px) {
      width: 90%;
      margin: 30px auto;
    }
  }
`;

const CustomTitle = styled(Modal.Title)`
float: left;
width: 100%;

> div {
  float: left;
  width: 100%;
  display: flex;
  > div {
    font-size: 12px;
    color: #a9a9a9;
    display: flex;
  }

  > span {
    font-size: 18px;
    letter-spacing: 0px;
    font-family: ${BoldFont};
    color: white;
    width: 70%;
  }
  .closeImage{
    width: 30%;
    display: flex;
    justify-content: flex-end;
    >img{
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
}

@media (max-width: 500px) {
  padding: 0 10px;
}
`;

const ArrowContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content:center
  > div: first-child {
    width: ${({ width }) => width ? width : '0px'};
    display: flex;
    justify-content: flex-end;
    >img{
      width: auto;
      height: 30px;
      margin: auto;
      margin-right: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
      cursor:pointer;
    }
  }
  >div:nth-child(2){
    margin: auto;
    margin-left: 0px;
    margin-right: 0px;
  }
  >div:nth-child(3){
    width: 50px;
    display: flex;
    justify-content: flex-start;
    >img{
      width: auto;
      height: 30px;
      margin: auto;
      margin-left: 15px;
      margin-top: 15px;
      margin-bottom: 15px;
      cursor:pointer;
    }
  }
`;

const PaginationCircle = styled.div`
  width: 27px;
  height: 27px;
  border-radius: 6px;
  background-color: ${({ active }) => active ? '#005c87' : ''};
  display: inline-block;
  margin-left: 10px;
  font-size: 14px;
  letter-spacing: 0px;
  font-family: rubik;
  margin: auto;
  margin-left: 10px;
  margin-right: 0px;
  > div {
    color: ${({ active }) => active ? '#ffffff' : '#b3cfdb' };
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }
`;

export {StyledTitle, StyledSubTitle, FormWrapper, InputFieldContainer, SearchButton, AddButton, AttendeesContainer, StyledModal, CustomTitle, ArrowContainer, PaginationCircle};
